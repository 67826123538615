import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';

import Box from './Box';
import Flex from './Flex';

// class VerticalCenter extends PureComponent {
//   state = {
//     count: 0,
//   }

//   handleContainerRef = (ref) => {
//     this.containerRef = ref;
//   }

//   handleResize = (contentRect) => {
//     const { count, shouldCenter } = this.state;
//     const shouldCenterNow = typeof this.containerRef !== 'undefined' && this.containerRef.getBoundingClientRect().height > contentRect.bounds.height;
//     this.setState({
//       count: count + Number(shouldCenter !== shouldCenterNow),
//       shouldCenter: count > 4 ? shouldCenter : shouldCenterNow,
//     });
//   }

//   render() {
//     const {
//       children,
//       horizon,
//       ...props
//     } = this.props;
//     const { shouldCenter } = this.state;
//     return (
//       <Flex
//         flexDirection="column"
//         position="relative"
//         minHeight="100%"
//         alignItems="center"
//         {...props}
//       >
//         <Box>
//           {children}
//         </Box>
//       </Flex>
//     );
//   }
// }

const VerticalCenter = ({ children, ...props }) => {
  return (
    <Flex
      flexDirection="column"
      position="relative"
      minHeight="100%"
      justifyContent="center"
      {...props}
    >
      <Box>
        {children}
      </Box>
    </Flex>
  );
}

VerticalCenter.propTypes = {
  children: PropTypes.node,
};

VerticalCenter.displayName = 'VerticalCenter';

export default VerticalCenter;
