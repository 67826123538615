import React, { createElement, PureComponent, Fragment, useMemo } from 'react';
import { graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import get from 'lodash/get';
import fromPairs from 'lodash/fromPairs';

import Container from '../components/Container';
import VerticalCenter from '../components/VerticalCenter';
import Button from '../components/Button';
import Box from '../components/Box';
import Link from '../components/Link';
import Layout from '../containers/Layout';
import { selectCompleted } from '../stores/surveyStore';
import checkImage from '../components/utils/checkImage';
import getModule from './getModule';
import RefRecorder from '../containers/RefRecorder';

const renderModules = (modules) => modules.map(({ module, content, props, src }, key) => {
  const Module = getModule(module);
  if (!Module) return null;
  return (
    <Module
      {...fromPairs(props)}
      src={src}
      my="1em"
      key={key}
    >
      {content}
    </Module>
  );
});

class General extends PureComponent {
  constructor(props) {
    super(props);
    const {
      data: {
        survey: { modules, image },
      },
    } = props;
    const ogImage = image || get(modules.find(({ module }) => module === 'image'), 'src');
    this.ogImage = ogImage && checkImage(ogImage);
  }

  render() {
    const {
      data: {
        survey: { type, modules, slug, action, title, subtitle, props: pageProps, primaryColor },
      },
      completed,
    } = this.props;
    const isWelcome = type === 'welcome';
    if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined' &&!isWelcome && !completed) {
      navigate(slug, { replace: true });
      return null;
    }
    return (
      <Layout noHeader themeOverride={primaryColor && { colors: { primary: primaryColor } }}>
        {(siteMetadata) => (
          <VerticalCenter>
            {isWelcome && (
              <Fragment>
                <Helmet titleTemplate={`%s | ${siteMetadata.title}`}>
                  <title>{title}</title>
                  {subtitle && <meta name="description" content={subtitle} />}
                  <meta name="og:url" content={siteMetadata.url + '/' + slug} />
                  {this.ogImage && <meta name="og:image" content={siteMetadata.url + this.ogImage} />}
                </Helmet>
                <RefRecorder />
              </Fragment>
            )}
            {createElement(Container, pageProps ? fromPairs(pageProps) : {}, (
              <Fragment>
                {renderModules(modules)}
                {isWelcome && (
                  <Box my="2em" textAlign="center">
                    <Button is={Link} to={`/${slug}/1/`}>
                      {action || '開始'}
                    </Button>
                  </Box>
                )}
              </Fragment>
            ))}
          </VerticalCenter>
        )}
      </Layout>
    )
  }
}

const Gen = (props) => {
  const { type, slug } = props.data.survey;
  return useMemo(() => createElement(type === 'thanks' ? connect(selectCompleted(slug))(General) : General, props), [type, slug]);
};

export default Gen

export const query = graphql`
  query($slug: String!, $type: String!) {
    survey(slug: { eq: $slug }, type: { eq: $type }) {
      slug
      type
      action
      title
      subtitle
      image
      props
      primaryColor
      modules {
        module
        content
        src
        props
      }
    }
  }
`
