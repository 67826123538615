import { connect } from 'react-redux'
import { useEffect } from 'react'

import { mapDispatchToProps } from '../stores/surveyStore'

const RefRecorder = ({ setSource }) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setSource({
      source: params.get('utm_source') || '',
      medium: params.get('utm_medium') || '',
      campaign: params.get('utm_campaign') || '',
    })
  }, [])
  return null
}

export default connect(undefined, mapDispatchToProps)(RefRecorder)
